import React, { useState } from "react";
import "./styles.scss";
import Text2SQLSection from "./Text2SQLSection";
import AgentSection from "./DataAgentSection";
import SemanticSearchSection from "./SemanticSearchSection";

import ContentTabs from "../../../ContentTabs/ContentTabs";

import {
  AutoAwesome,
  SearchAwesome,
  Terminal,
} from "../../../IconsCollections/IconsCollections";

function AiFeatureSection() {
  const sections = [
    {
      title: "AI Agents",
      component: <AgentSection />,
      icon: <AutoAwesome />,
    },
    {
      title: "Text to SQL",
      component: <Text2SQLSection />,
      icon: <Terminal />,
    },
    {
      title: "Semantic Search",
      component: <SemanticSearchSection />,
      icon: <SearchAwesome />,
    },
  ];

  const [selected, setSelected] = useState(sections[0].component);

  const onTabClick = (section) => {
    const { component } = section;
    setSelected(component);
  };

  return (
    <div className="page-section" id="aiFeatureSection">
      <div className="page-container container rounded py-3">
        <div className="d-flex align-items-center justify-content-center">
          <ContentTabs sections={sections} onTabClick={onTabClick} />
        </div>
        <div>{selected}</div>
      </div>
    </div>
  );
}

export default AiFeatureSection;
