import React from 'react';
import { Link } from "react-router-dom";
import "./styles.scss";
function Announcement({show}) {

  return (
    <div className = { `${show ? "d-block" : "d-none"} bg-primary text-white position-relative` }
        id = "announcement-topbar">
          <div className='d-flex align-items-center justify-content-center gap-3 h-100 px-3 position-relative inner'>
            <div className='text-center m-2 my-sm-2 my-md-0 my-lg-0'>
              <span className = "bg-info bg-gradient fw-bold px-2 px-lg-3 px-md-2 px-sm-2 py-1 me-2 fs-14px rounded text-primary shadow-md">
                  New
              </span>
              <span className = "text-white me-2 ">
                  Announcing AI Agent Management System, Now create, test and deploy enterprise AI agents at scale
              </span>
              <span className='me-2 d-inline-block '>
                <Link to = "/get-started" onClick = {() => {}}>
                  <div className='text-info d-flex gap-2 fw-bold getStartedText cursor-pointer'>
                    Get Started 
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 -960 960 960" ><path d="m560-242-43-42 168-168H160v-60h525L516-681l43-42 241 241-240 240Z"/></svg>
                  </div>
                </Link>
              </span>
            </div>
        </div>
    </div>
  )
}

export default Announcement;
