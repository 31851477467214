import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";

export default class DataScientist extends Component {
	render() {
		return (
			<div className="page" id="dataScientistRolePage">
				<div className="page-section">
					<div className="page-container container hero-container">
						<div className="row justify-content-center">
							<div className="col-lg-8 text-center">
								<h1>Xceed Intelligence Platform for Data Scientist</h1>
								<p className="fs-6">
									Focus on creating business value and not on how to deploy and
									manage infrastructure. Xceed Intelligence Platform provides comprehensive
									capabilities for the unique needs of data scientists, with
									focus on accelarating both model development and deployment
									process, while letting data scientist have full control and
									flexibility on the whole process.
								</p>
							</div>
							<div className="col-lg-12">
								<img
									src="/images/dataScientistRole.png"
									width="100%"
									alt="data Scientist"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="page-section">
								<div className="page-container container">
									<h3>Data Modelling</h3>
									<div>
										<p>
											<span className="me-2">1.</span> Explore all the
											enterprise data without friction instantly using data
											catalog.
										</p>
										<p>
											<span className="me-2">2.</span> Quick Explore Data
											profile to understand fo every dataset available.
										</p>
										<p>
											<span className="me-2">3.</span> Explore, Model data and
											create new features using Xceed Intelligence Platform Workflow
											Designer.
										</p>
										<p>
											<span className="me-2">4.</span> Create, Automate and
											Publish new datasets to the Data Catalog in hours.
										</p>
									</div>
								</div>
							</div>
							<div className="page-section">
								<div className="page-container container">
									<h3>Model Building</h3>
									<div>
										<p>
											<span className="me-2">1.</span>
											Quickly prototype and build predictive models using a
											catalog of readily available machine learning algorithms.
											Combine models and build ensembles.
										</p>
										<p>
											<span className="me-2">2.</span>
											Check the leaderboard for scores and metrics for the best
											model. Review readily available model reports. Quickly
											understand the global and local interpretability via model
											explainability report.
										</p>
										<p>
											<span className="me-2">3.</span>
											Iterate back as required.
										</p>
									</div>
								</div>
							</div>
							<div className="page-section">
								<div className="page-container container">
									<h3>Model Deployment</h3>
									<div>
										<p>
											<span className="me-2">1.</span>
											Publish model and model artifacts to model catalog. Stay
											on top of model training parameters, changes and metrics
											across model versions.
										</p>
										<p>
											<span className="me-2">2.</span>
											Deploy and monitor your ML model product instantly to
											production.
										</p>
									</div>
								</div>
							</div>
							<ContactUsSection />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
