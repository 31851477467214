import {
  analytics,
  assignment_ind,
  award_star,
  bring_your_own_ip,
  build_circle,
  campaign,
  car_crash,
  chart_data,
  chat_person,
  clinical_notes,
  contact_phone,
  contacts,
  contract,
  conveyor_belt,
  credit_card,
  document_scanner,
  energy_stand,
  experiment,
  finance_chip,
  finance_mode,
  flowsheet,
  folder_data,
  graph_2,
  help,
  help_center,
  inventory,
  lab_panel,
  lab_profile,
  local_shipping,
  map_search,
  multiline_chart,
  orders,
  perm_contact_calendar,
  person_add,
  person_search,
  personal_injury,
  point_of_sale,
  policy_alert,
  price_change,
  price_check,
  psychology_alt,
  query_stats,
  receipt_long,
  rule,
  safety_check,
  sdk,
  shopping_bag,
  support_agent,
  text_compare,
  ticket,
  tire_repair,
  user_attributes,
  warehouse,
  web_asset,
} from "../../IconsCollections/IconsCollections";

export const navigationSectionsByFunction = [
  {
    tabIndex: 0,
    tabTitle: "Sales & Marketing",
    content: [
      {
        title: "Lead & Account Insights",
        description:
          "Enabling teams to retrieve lead and account details instantly. access to leads and accounts in CRM",
        IconComponent: user_attributes,
      },
      {
        title: "Lead Generation Agent",
        description:
          "Attracting and retaining top talent is crucial for a graphic design agency to achieve its goals.",
        IconComponent: person_add,
      },
      {
        title: "Scheduling Agent",
        description:
          "Automate call scheduling, integrate with calendars and CRM, and provide pre-call insights.",
        IconComponent: perm_contact_calendar,
      },
      {
        title: "Marketing Campaign Agent",
        description:
          "analyze customer data to create targeted marketing campaigns, optimizing outreach and engagement.",
        IconComponent: campaign,
      },
    ],
  },
  {
    tabIndex: 1,
    tabTitle: "IT & Security",
    content: [
      {
        title: "IT Helpdesk Agent",
        description:
          "Provide instant IT support by answering employee queries using internal knowledge sources.",
        IconComponent: help_center,
      },
      {
        title: "Software Update Agent",
        description:
          "Manage software updates, approvals, and deployments, ensuring a smooth and efficient process.",
        IconComponent: sdk,
      },
      {
        title: "IT Asset Management",
        description:
          "AI agents track, manage, and automate IT asset workflows, enabling remote actions and lifecycle management.",
        IconComponent: web_asset,
      },
      {
        title: "IT Advisor Agent",
        description:
          "Analyze recurring issues, identify root causes, and suggest proactive solutions to prevent future incidents.",
        IconComponent: psychology_alt,
      },
    ],
  },
  {
    tabIndex: 2,
    tabTitle: "Data & Analytics",
    content: [
      {
        title: "Data Discovery Agent",
        description:
          "improve data discoverability, metadata management, and provide actionable insights for better decision-making",
        IconComponent: query_stats,
      },
      {
        title: "Data Analyst Agent",
        description:
          "Assist business users by helping find key insights, and simplifying analysis tasks",
        IconComponent: analytics,
      },
      {
        title: "Data Science Agent",
        description:
          "Support data scientists by automating model creation, data processing, and insightful analysis.",
        IconComponent: graph_2,
      },
      {
        title: "Data Engineer Agent",
        description:
          "Automates data cleaning, outlier detection, and preprocessing tasks, ensuring data quality for analysis.",
        IconComponent: clinical_notes,
      },
    ],
  },
  {
    tabIndex: 3,
    tabTitle: "HR & Recruitment",
    content: [
      {
        title: "Candidate Screening and Shortlisting",
        description:
          "Screen resumes, match qualifications with job descriptions, and shortlist the most suitable candidates.",
        IconComponent: contacts,
      },
      {
        title: "Interview Scheduling Agent",
        description:
          "Automates the scheduling of interviews by coordinating calendars, sending reminders, and managing rescheduling requests.",
        IconComponent: contact_phone,
      },
      {
        title: "Employee Onboarding",
        description:
          "Provide personalized onboarding experiences by guiding new hires through the paperwork, policies, and training materials",
        IconComponent: assignment_ind,
      },
      {
        title: "Talent Sourcing",
        description:
          "Proactively search for qualified candidates on job boards, social media, and professional networks, ensuring a robust talent pipeline",
        IconComponent: person_search,
      },
    ],
  },
  {
    tabIndex: 4,
    tabTitle: "Research & Development",
    content: [
      {
        title: "Literature Review Assistant",
        description:
          "Assist researchers by reviewing and summarizing relevant literature, saving time and ensuring proper coverage.",
        IconComponent: document_scanner,
      },
      {
        title: "Experimentation and Testing",
        description:
          "Automate and optimize testing procedures, identifying optimal conditions for experiments and suggesting improvements to test designs.",
        IconComponent: experiment,
      },
      {
        title: "Document Management",
        description:
          "Organize & Catalog research papers, project documents, and technical resources, improving collaboration & knowledge sharing within R&D teams",
        IconComponent: folder_data,
      },
      {
        title: "Trend Tracker",
        description:
          "Keep track of trends in research and development, forecasting future breakthroughs and guiding investment in emerging technologies",
        IconComponent: chart_data,
      },
    ],
  },
  {
    tabIndex: 5,
    tabTitle: "Finance",
    content: [
      {
        title: "Financial Reporting & Analysis",
        description:
          "Automate the generation of financial reports, analyze trends, and provide actionable insights for decision-making",
        IconComponent: finance_chip,
      },
      {
        title: "Expense Management Agent",
        description:
          "Automates expense categorization, approval workflows, and improve employee expense filing experience.",
        IconComponent: price_change,
      },
      {
        title: "Invoice Processing",
        description:
          "Extract, validate, and categorize invoice data, automating the approval and payment process for improved accuracy and efficiency",
        IconComponent: receipt_long,
      },
      {
        title: "Tax Compliance and Reporting",
        description:
          "Analyze financial records and generating tax reports, identifying potential deductions, and flagging risks",
        IconComponent: lab_profile,
      },
    ],
  },
  {
    tabIndex: 6,
    tabTitle: "Legal",
    content: [
      {
        title: "Contract Review and Analysis",
        description:
          "Analyze and review contracts, identifying key clauses, risks, and compliance issues, speeding up the contract approval process",
        IconComponent: contract,
      },
      {
        title: "Document Drafting Assistant",
        description:
          "Automates the drafting of standard legal documents such as contracts, NDAs, and legal notices, ensuring accuracy and consistency",
        IconComponent: text_compare,
      },
      {
        title: "Compliance Monitoring",
        description:
          "Track and ensure compliance with legal and regulatory requirements, flagging potential violations and automating reporting",
        IconComponent: policy_alert,
      },
      {
        title: "IP Management",
        description:
          "Manage IP portfolios by tracking patents, trademarks, and copyrights, ensuring timely renewals and detecting potential infringements",
        IconComponent: bring_your_own_ip,
      },
    ],
  },
  {
    tabIndex: 7,
    tabTitle: "Customer Service",
    content: [
      {
        title: "Customer Service Chatbot",
        description:
          "Provide 24/7 support through chat, answering common customer inquiries, troubleshooting issues, and resolving simple requests.",
        IconComponent: chat_person,
      },
      {
        title: "Automated Ticket Routing",
        description:
          "Categorize & prioritize customer support tickets, automatically routing them to the appropriate department or agent for quicker resolution.",
        IconComponent: ticket,
      },
      {
        title: "Knowledge Agents",
        description:
          "Assist customers by providing quick, context-driven answers from an organization's knowledge base, improving self-service capabilities.",
        IconComponent: flowsheet,
      },
      {
        title: "Order Assistant",
        description:
          "Guiding customers through self-service portals, assisting with order tracking, returns, and subscription management",
        IconComponent: orders,
      },
    ],
  },
];

export const navigationSectionsByIndustry = [
  {
    tabIndex: 0,
    tabTitle: "Financials",
    content: [
      {
        title: "Financial Advisor Agent",
        description:
          "Provide personalized financial advice and portfolio management, helping customers make informed investment decisions.",
        IconComponent: finance_mode,
      },
      {
        title: "Claims Processing Agent",
        description:
          "Automate insurance claims process by validating claims, assessing documents, and providing instant approval or rejection",
        IconComponent: rule,
      },
      {
        title: "Personalized Offers and Rewards",
        description:
          "Analyze spending behavior and transaction history to create personalized offers and reward programs, driving customer engagement and increasing loyalty.",
        IconComponent: award_star,
      },
      {
        title: "Customer Support for Credit Card Services",
        description:
          "Provide instant support for cardholders, handling inquiries related to account balances, payments, fraud disputes, and credit limits, enhancing customer satisfaction.",
        IconComponent: credit_card,
      },
    ],
  },
  {
    tabIndex: 1,
    tabTitle: "Retail & Ecommerce",
    content: [
      {
        title: "Personalized Shopping Assistant",
        description:
          "Provide personalized product recommendations based on browsing history, preferences, & past purchases, boosting conversions & customer satisfaction",
        IconComponent: shopping_bag,
      },
      {
        title: "Proactive Inventory Management",
        description:
          "Predict proactively inventory needs based on trends, seasonality, and historical sales data, optimizing stock levels and reducing waste",
        IconComponent: inventory,
      },
      {
        title: "Customer Queries & Order Management",
        description:
          "Handle customer queries related to orders, returns, and product details, providing immediate responses and reducing the burden on human agents",
        IconComponent: help,
      },
      {
        title: "Price Optimization Agent",
        description:
          "Analyze competitor pricing, demand fluctuations, & customer behavior to dynamically adjust prices in real-time, maximizing revenue & maintaining competitive edge.",
        IconComponent: price_check,
      },
    ],
  },
  {
    tabIndex: 2,
    tabTitle: "IT & Business Process Management",
    content: [
      {
        title: "IT Helpdesk Agent",
        description:
          "Provide instant IT support by answering employee queries using internal knowledge sources.",
        IconComponent: help_center,
      },
      {
        title: "Software Update Agent",
        description:
          "Manage software updates, approvals, and deployments, ensuring a smooth and efficient process.",
        IconComponent: sdk,
      },
      {
        title: "IT Asset Management",
        description:
          "AI agents track, manage, and automate IT asset workflows, enabling remote actions and lifecycle management.",
        IconComponent: web_asset,
      },
      {
        title: "IT Advisor Agent",
        description:
          "Analyze recurring issues, identify root causes, and suggest proactive solutions to prevent future incidents.",
        IconComponent: psychology_alt,
      },
    ],
  },
  {
    tabIndex: 3,
    tabTitle: "Industrials & Manufacturing",
    content: [
      {
        title: "Predictive Maintenance",
        description:
          "Monitor equipment health and predict potential failures, minimizing downtime and reducing maintenance costs by addressing issues before they escalate",
        IconComponent: build_circle,
      },
      {
        title: "Supply Chain Optimization",
        description:
          "Analyzes supply chain data to predict demand fluctuations, optimize inventory levels, and reduce lead times for more efficient operations",
        IconComponent: local_shipping,
      },
      {
        title: "Safety Monitoring and Incident Prevention",
        description:
          "Monitor operations for safety risks, analyzing data from sensors to predict and prevent accidents, ensuring a safer work environment.",
        IconComponent: safety_check,
      },
      {
        title: "Energy Production Optimization",
        description:
          "analyzing data on equipment performance, environmental conditions, & market prices, ensuring maximum output with minimal resource use",
        IconComponent: energy_stand,
      },
    ],
  },
  {
    tabIndex: 4,
    tabTitle: "Logistics & Supply Chain",
    content: [
      {
        title: "Route Optimization Agent",
        description:
          "Analyze traffic, weather, and delivery data to optimize delivery routes in real-time, reducing fuel consumption and improving delivery times",
        IconComponent: map_search,
      },
      {
        title: "Supplier Performance Tracking",
        description:
          "Analyze supplier performance, pricing trends, and contractual terms to recommend optimal procurement strategies.",
        IconComponent: multiline_chart,
      },
      {
        title: "Inventory Management",
        description:
          "Proactively track demand and optimize warehouse inventory management, ensuring products are available when needed and reducing stockouts or overstocking",
        IconComponent: warehouse,
      },
      {
        title: "Predictive Maintenance for Fleet",
        description:
          "Monitor the health of logistics fleets, predicting and preventing breakdowns by scheduling maintenance before issues occur",
        IconComponent: tire_repair,
      },
    ],
  },
  {
    tabIndex: 5,
    tabTitle: "Pharma & Healthcare",
    content: [
      {
        title: "Patient Support Chatbots",
        description:
          "Provide 24/7 support for patients, answering medical questions, sending reminders for medication, and offering mental health support",
        IconComponent: support_agent,
      },
      {
        title: "Clinical Trial Matching",
        description:
          "Match patients with appropriate clinical trials based on their medical history, improving recruitment efficiency and trial outcomes",
        IconComponent: lab_panel,
      },
      {
        title: "Personalized Treatment Plans",
        description:
          "Process patient data, including medical history, genetics, and current conditions, to recommend personalized treatment plans, improving outcomes and reducing trial-and-error methods.",
        IconComponent: personal_injury,
      },
      {
        title: "Virtual Health Assistants",
        description:
          "Assistants that provide patients with health information, medication reminders, and guidance on managing chronic conditions, improving patient engagement and compliance.",
        IconComponent: support_agent,
      },
    ],
  },
  {
    tabIndex: 6,
    tabTitle: "Automotive",
    content: [
      {
        title: "Predictive Maintenance for Vehicles",
        description:
          "Monitor vehicle data to predict maintenance needs, reducing the likelihood of breakdowns and extending the lifespan of automotive assets",
        IconComponent: car_crash,
      },
      {
        title: "Vehicle Sales and Marketing",
        description:
          "Personalized vehicle recommendations to customers based on preferences, budget, and past behavior, driving more targeted sales & marketing campaigns.",
        IconComponent: point_of_sale,
      },
      {
        title: "Customer Support for Vehicle Services",
        description:
          "Handle customer queries about vehicle services, warranties, and repairs, offering immediate assistance and facilitating appointment bookings.",
        IconComponent: support_agent,
      },
      {
        title: "Supply Chain & Parts Management",
        description:
          "Proactively analyze inventory data, supplier performance, and demand forecasts to optimize parts supply chains, ensuring timely deliveries and reducing production downtime.",
        IconComponent: conveyor_belt,
      },
    ],
  },
];
