import React from "react";
import ImageSlideshow from "../../../ImageSlideshow";

const AgentSection = () => {
  const data = [
    "Create purpose build AI agents effortlessly using Xceed Agent builder",
    "Take Actions to improve reliability by adding trusted knowledge and queries right from the data catalog/agent builder",
    "Perform a quick smell test using agent playground",
    "Conduct extensive evaluation with experiments, before deploying agents",
    "Deploy the agent with one-click",
    "Observe agents in productions wth quick metrics and drill into details.",
  ];
  return (
    <div className="page-section" id="Text2SQLSection">
      <div className="page-container container rounded py-3">
        <div className="row align-items-start justify-content-between g-3 p-5">
          <div className="col-xxl-6 col-lg-6 ">
            <h3 className="">Create, Test & Deploy AI agents at scale</h3>

            <div className="d-flex flex-column gap-3">
              {data.map((item, i) => {
                return (
                  <div key={i} className="border white px-3 py-3 rounded">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xxl-5 col-lg-5">
            <ImageSlideshow
              images={[
                "/images/agentConversation.svg",
                "/images/MetricsDashboard.svg",
                "/images/monitoring_details.svg",
              ]}
              timerLimit={3000}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentSection;
