import React, { Component } from "react";
import "./modelCatalog.scss";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";

export default class ModelCatalog extends Component {
  render() {
    return (
      <div className="page" id="modelCatalogPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-3">
              <div className="col-lg-12 col-md-12 text-center mb-4">
                <h1 className="mb-3">Discover, Govern & Collaborate</h1>
                <p className="fs-5 mb-4">
                  360 degree visibility into all your ML/AI models,
                  <br />
                  artificats, revisions and deployments from a single view.
                </p>
                <CtaButtons center={true} />
              </div>
              <div className="col-lg-12  col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/model_catalog/mlCatalogBanner.svg"
                    width="80%"
                    alt="Xceed model catalog banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>
                  Organize, Govern all your ML/AI artifacts and deployments
                </h2>
                <p>
                  Xceed Model Catalog stands out by offering versatile support
                  for both Classical ML models and AI models. Notably, it goes
                  beyond traditional model management by incorporating robust
                  support for model explainability out of the box, a crucial
                  feature for Classical ML models. Additionally, the catalog
                  embraces the landscape of Natural Language Processing (NLP)
                  and Open-Source Software (OSS) Large Language Models (LLM) by
                  facilitating the seamless import of models from public
                  repositories like Hugging Face.
                  <br />
                  <br />A Comprehensive approach to making model catalog
                  effective further includes governance, version control and
                  deployment, consolidating these critical functions into a
                  single, user-friendly interface. Integrated within Xceed
                  intelligent data platform not only enhances efficiency but
                  also ensures consistency and transparency throughout the model
                  lifecycle, making it an indispensable tool for organizations
                  navigating the complexities of ML/AI workflows.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-center g-5">
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  Better manage your enterprise ML/AI model aseets
                </h6>
                <h3>One place to find all your model assets</h3>
                <ListComponent
                  data={[
                    "All the models are instantly available to whoever needs it.",
                    "No hassle of maintaining model revisions. Find historical versions of the model whenever you need.",
                    "One click CPU/GPU deployment right from within catalog",
                    "Optimize your model througput/cost by deploying multiple models on the same deployment instance.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/model_catalog/modelCatalog.svg"
                    width="100%"
                    alt="cynepia model catalog"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container ">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/model_catalog/playground.svg"
                    width="100%"
                    alt="cynepia model catalog"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">Playground</h6>
                <h3>Evaluate models using Playground</h3>
                <ListComponent
                  data={[
                    "Play ground enables evaluating models in Staging before creating a production version",
                    "Compare and Evaluate two model revisions or two different models in same category",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Govern, Deploy/Serve
                </h6>
                <h3>Govern and Serve</h3>
                <ListComponent
                  data={[
                    "Share your data assets across all the data teams who need access to your model instantly.",
                    "Granular access control & governance with dataset access control and row-level filters within each dataset ensures that users see only the records that they are entitled to.",
                    "Deploy model instantly on any CPU/GPU of your choice",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/model_catalog/deploy_and_serve.svg"
                    width="100%"
                    alt="cynepia model catalog"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3 align-items-strech justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/singleSourceOfTruth.svg"
                        alt="cynepia model catalog"
                        width={75}
                      />
                    </div>
                    <h5 className="title">Single Source of Truth</h5>
                    <div className="description">
                      All the ML/AI model artifacts available from one single
                      view.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/previewMetadata.svg"
                        alt="cynepia model catalog"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Model Explainability</h5>
                    <div className="description">
                      Quick preview of model artifacts including associated
                      description and Explainability*
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/model_catalog/test_eval_icon.svg"
                        alt="model test and eval"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Evaluate/Test Models</h5>
                    <div className="description">
                      Evaluate and test model directly on Xceed Intelligence Platform via
                      Playground
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/peopleGroup.svg"
                        alt="Xceed Playground"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Collaborate & Govern</h5>
                    <div className="description">
                      Share & Collaborate with other ML/AI team members within
                      the organisation.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/badge.svg"
                        alt="cynepia model catalog"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Ensure Model Auditability</h5>
                    <div className="description">
                      Quickly Audit Explainability for all the revisions of the
                      model
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/securityCheck.svg"
                        alt="cynepia model catalog"
                        width={40}
                      />
                    </div>
                    <h5 className="title">Enterprise Grade Security</h5>
                    <div className="description">
                      Granular Governance ensures everyone has access to what is
                      required for their specific role.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/versionModels.svg"
                        alt="cynepia modle catalog"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Versioned ML Models</h5>
                    <div className="description">
                      Track every revision from the catalog revisions page.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/model_catalog/api_icon.svg"
                        alt="Xceed foundation model apis"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Xceed Foundation Model API Client</h5>
                    <div className="description">
                      Xceed foundation model APIs provide instant access to your
                      deployed models.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/model_catalog/deploy_icon.svg"
                        alt="cynepia model catalog"
                        width={60}
                      />
                    </div>
                    <h5 className="title">Serve</h5>
                    <div className="description">
                      Serve all model types while optimizing your model
                      througput/cost.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
