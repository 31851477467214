import React, { Component } from "react";
import SocialIcons from "../socialIcons/socialIcons";
import { Link } from "react-router-dom";
import "./footer.scss";
import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";
import SubscribeSection from "../subscribeSection/subscribeSection";

export default class Footer extends Component {
  render() {
    return (
      <div className="secondary-bg footer-wrapper" id="footer-section">
        <SubscribeSection />
        <div className=" page-section ">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-lg-4 col-md-12 col-sm-12 ">
                <div className="p-0 container-fluid">
                  <div className="row justify-content-between flex-wrap">
                    <div className="logo-wrapper mb-4 col-auto">
                      <img
                        src="/images/logos/logo_light.svg"
                        width="150"
                        alt="cynepia"
                      />
                    </div>
                    <div className="col-auto">
                      <SocialIcons bg="dark" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 ">
                <h5 className="mb-4">Product</h5>
                <ul className="list-group gap-2 list-unstyled">
                  <li>
                    <Link to="/products/ai-agents">AI Agents </Link>
                  </li>
                 <li>
                    <Link to="/products/self-service-bi">Self Service BI</Link>
                  </li>

                  <li>
                    <Link to="/products/data-catalog">Data Catalog</Link>
                  </li>
                  <li>
                    <Link to="/products/model-catalog">
                      Model Catalog
                    </Link>
                  </li>

	          <li>
                    <Link to="/products/ai-agents">Agent Orchestrator</Link>
                  </li>

                  <li>
                    <Link to="/products/data-preparation">
                      Data Preparation
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/xceed-analytics-sql-Workbench">
                      SQL Workbench 
                    </Link>
                  </li>

                  <li>
                    <Link to="/products/data-connectors">Data Connectors</Link>
                  </li>

                  <li>
                    <Link to="/products/architecture">Architecture</Link>
                  </li>
                  <li>
                    <Link to="/products/pricing">Pricing</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 ">
                <h5 className="mb-4">Stories</h5>
                <ul className="list-group gap-2 list-unstyled">
                  <li>
                    <Link to="/stories?industry=financial-services">
                      Financial Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/stories?industry=retail-services">
                      Retail & CPG
                    </Link>
                  </li>
                  <li>
                    <Link to="/stories?role=data-analyst">Data Analyst</Link>
                  </li>
                  <li>
                    <Link to="/stories?role=data-scientist">
                      Data Scientist
                    </Link>
                  </li>
                  <li>
                    <Link to="/stories?role=data-engineer">Data Engineer</Link>
                  </li>
                  <li>
                    <Link to="/stories?role=business-user">Business User</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 ">
                <h5 className="mb-4">Resources</h5>
                <ul className="list-group gap-2 list-unstyled">
                  <li>
                    <span
                      className="footer-link cursor-pointer"
                      onClick={() => {
                        window.open(window.__DocumentationUrl__, "_blank");
                      }}
                    >
                      <AnimatedArrowIconLink
                        content="Documentation"
                        classNames=" link "
                      />
                    </span>
                  </li>
                  <li>
                    <span
                      className="footer-link cursor-pointer"
                      onClick={() => {
                        window.open(window.__BlogUrl__, "_blank");
                      }}
                    >
                      <AnimatedArrowIconLink content="Blogs" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 ">
                <h5 className="mb-4">Company</h5>
                <ul className="list-group gap-2 list-unstyled">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <span
                      className="footer-link cursor-pointer"
                      onClick={() => {
                        window.open(window.__CareersUrl__, "_blank");
                      }}
                    >
                      Careers
                    </span>
                  </li>
                  <li>
                    <Link to="/partners">Partners</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <div className="container py-4 border-top border-light">
            <div className="d-flex justify-content-between align-items-center">
              <small className="text-secondary">
                @ Cynepia Technologies © 2022-2023
              </small>
              <small className="d-flex gap-3">
                <Link className="text-secondary" to="/privacy">
                  Privacy Policy
                </Link>
                <Link className="text-secondary" to="/terms">
                  Terms of Use
                </Link>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
