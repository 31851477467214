import React from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";

import "./aiAgentView.scss";
import ContentTabbedSection from "./ContentTabbedSection";
import AgentUseCasesSection from "../../home/AgentUseCasesSection";

const AiAgentView = () => {
  return (
    <div className="page" id="dataEngineeringAndMlPage">
      <div className="page-section">
        <div className="page-container container hero-container">
          <div className="row align-items-center g-3">
            <div className="col-lg-12 col-md-12 text-center mb-4">
              <h1 className="mb-3">Build Evaluate & Deploy AI Agents</h1>
              <p className="fs-5 mb-4">
                Automate tasks using no-code Agent Builder,<br></br>
                evaluate, deploy and monitor all from one unified view
              </p>
              <CtaButtons center={true} />
            </div>
            <div className="col-lg-12  col-md-12 h-100 right-section">
              <div className="img-wrapper text-center">
                <img
                  src="/images/aiOverview.svg"
                  width="100%"
                  alt="Xceed Data Agent"
                  className="rounded-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-container container">
          <div className="row">
            <div className="mb-5">
              <h2>Overview</h2>
              <p className="fs-5">
                Xceed AI agents helps you build, evaluate, and deploy AI agents
                and converse with the agents through a familiar chat interface.
                Creating dedicated agent force for your enterprise was never as
                easy.
                <br></br>
                <br></br>
                Pre-built agents such as Jane enable business teams can
                effortlessly create a story/dashboard without any knowledge of
                SQL. Data analyst teams support and monitor responses and
                influence by constantly improving its knowledge base. With
                additional custom knowledge, Jane becomes smarter and reliable.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="/images/agentFlow.svg"
                width="80%"
                alt="agent architecture"
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-start g-5">
            <div className="col-12">
              <h6 className="pre-heading-highlight fw-bold">
                Purpose built AI Agents
              </h6>
              <h3>Build AI Agents for your enterprise</h3>
              <div>
                Create custom agents for your specific usecase using Xceed Agent
                Builder and Xceed Intelligence SDK. Supports creating complex
                workflows that can chain multiple agentic tasks in a single
                agentic workflow.
              </div>
            </div>
            <div className="col-12">
              <div className="img-wrapper text-center">
                <img
                  src="/images/AgentCreateBordered.svg"
                  width="100%"
                  alt="Data agents for all roles"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mb-0">
        <ContentTabbedSection />
      </div>

      <AgentUseCasesSection />

      <ContactUsSection />
    </div>
  );
};

export default AiAgentView;
