import React, { Component } from "react";
import { Link } from "react-router-dom";
import CtaButtons from "../../ctaButtons/ctaButtons";
import { CountryList, DialCodeList } from "../../../utilityData/utilityData";
const timezones = require("timezones.json");

const PartnershipType = [
  "Channel Partners/Resellers",
  "OEMs",
  "Technology Partners",
];

export default class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      formSubmitStatus: null,
      requestOnGoing: false,
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    debugger;
    const {
      firstname,
      lastname,
      dialcode,
      phone,
      jobtitle,
      country,
      zip,
      scheduledate,
      last_schedule_meeting_time_zone,
      email,
      partner_type,
    } = e.target.elements;

    let bodyParams = {
      firstname: firstname.value,
      lastname: lastname.value,
      phone: dialcode.value + phone.value,
      jobtitle: jobtitle.value,
      country: country.value,
      zip: zip.value,
      scheduledate: scheduledate.value,
      last_schedule_meeting_time_zone: last_schedule_meeting_time_zone.value,
      email: email.value,
      partner_type: partner_type.value,
      lead_source: "Web-Partners-Request",
    };

    const params = JSON.stringify(bodyParams);
    const encodedParams = window.btoa(params);

    let url = window.__CreateContact__ + `/${encodedParams}`;

    this.setState({
      requestOnGoing: true,
    });

    fetch(url, {
      method: "GET",
      body: params,
    })
      .then((res) => res.json())
      .then((res) => {
        let formSubmitStatus, formSubmitted;
        formSubmitted = true;
        if (res.status === 200) {
          formSubmitStatus = "Success";
        } else {
          formSubmitStatus = "Failed";
          console.error(res);
        }
        this.setState({
          requestOnGoing: false,
          formSubmitStatus,
          formSubmitted,
        });
      });
  }

  render() {
    return (
      <div className="page" id="partnersPage">
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5 justify-content-between">
              <div className="col-lg-6">
                <h1>
                  Think Partnership
                  <br />
                  Think <span className="text-primary">Future</span>
                </h1>
                <p className="mb-5">
                  We are on the mission to help enterprises innovate and
                  transition to data-driven future. The Xceed Intelligence 
                  Platform enables service providers and re-sellers to generate revenues by elevating
                  customer value many-folds. Let us together help enterprises
                  accelerate and transition into data driven future.
                </p>
                <CtaButtons />
              </div>
              <div className="col-lg-6">
                <div className="img-wrapper d-flex align-items-center justify-content-center">
                  <img
                    src="/images/partnership.jpg"
                    alt="cynepia partners"
                    width="90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <h2 className="mb-5">Partnership Benefits</h2>
            <div className="feature-card d-flex g-5 mb-4 row">
              <div className="img-wrapper d-flex justify-content-center mb-4 col-lg-2">
                <img
                  src="/images/icons/colored/reseller.svg"
                  alt="partnership"
                  width={70}
                />
              </div>
              <div className="feature-card-body col-lg-10">
                <div className="title">
                  <h5>Channel Partners/Re-sellers</h5>
                </div>
                <p>
                  Cynepia re-sellers and channel partners are technology service
                  providers that resell Xceed Intelligence platform in addition to their own
                  deployment, implementation and services.
                </p>
                <p>
                  Cynepia Insights Platform provides an industry leading modern
                  BI architecture that enables channel providers and re-sellers
                  to drive revenue by providing data integration and analytics
                  solutions tailored to their customers requirements.
                </p>
              </div>
            </div>
            <div className="feature-card d-flex g-5 mb-4 row">
              <div className="img-wrapper d-flex justify-content-center mb-4 col-lg-2">
                <img
                  src="/images/icons/colored/cloudProvider.svg"
                  alt="partnership"
                  width={70}
                />
              </div>
              <div className="feature-card-body col-lg-10">
                <div className="title">
                  <h5>OEMs</h5>
                </div>
                <p>
                  Independent Software Vendors, Data Providers, laaS Cloud
                  Providers can tightly integrate their solutions with Cynepia's
                  Xceed Intelligence platform to provide a better solutions to
                  common customers.
                </p>
                <p>
                  We see a great value in providing tighter integration of our
                  partners offerings along with Cynepia's platform to provide a
                  better end-to-end solutions to joint customers.
                </p>
              </div>
            </div>
            <div className="feature-card d-flex g-5 row">
              <div className="img-wrapper d-flex justify-content-center mb-4 col-lg-2">
                <img
                  src="/images/icons/colored/technology.svg"
                  alt="partnership"
                  width={70}
                />
              </div>
              <div className="feature-card-body col-lg-10">
                <div className="title">
                  <h5>Technology Partners</h5>
                </div>
                <p>
                  Cynepia is committed to developing strong technology alliances
                  in the Data, Analytics and Cloud infrastructure Space.
                </p>
                <p>
                  We work closely with our partners to maintain compatibility
                  with the most current platform releases to give our mutual
                  customers the flexibility to deploy a powerful analytics
                  solution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section accent-bg-2">
          <div className="page-container container">
            <h1 className="text-center">Be a Partner</h1>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8">
                <div className="form-wrapper px-5 py-5 bg-white border rounded">
                  {this.state.formSubmitted ? (
                    this.state.formSubmitStatus === "Success" ? (
                      <>
                        <div className="alert alert-success" role="alert">
                          Thank you for submitting the form. Check your mail for
                          confirmation. We will see you soon.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="alert alert-danger" role="alert">
                          Something went wrong! Please try again.
                        </div>
                      </>
                    )
                  ) : (
                    ""
                  )}
                  <h5 className="mb-4">Send us a message</h5>
                  <form
                    className="cyn-form"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <div className="mb-3">
                      <div className="row g-3 align-items-center">
                        <div className="col-lg-6 col-md-12">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="firstName"
                          >
                            First Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstname"
                            aria-describedby="firstName"
                            placeholder="Enter first name"
                            required
                            maxLength="20"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="lastName"
                          >
                            Last Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastname"
                            aria-describedby="lastName"
                            placeholder="Enter last name"
                            required
                            maxLength="20"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label text-secondary text-small"
                        htmlFor="partner_type"
                      >
                        Type of Partnership
                      </label>
                      <select
                        className="form-select"
                        id="partner_type"
                        name="partner_type"
                        required
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Type of Partners
                        </option>
                        {PartnershipType.map((name, i) => {
                          return (
                            <option key={i} value={name}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <div className="row g-3 align-items-end">
                        <div className="col-lg-6">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="companyEmail"
                          >
                            Company Email *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="companyEmail"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter your company email"
                            required
                            maxLength="40"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="w-100 form-label text-secondary text-small">
                            Enter mobile number with country code
                          </label>
                          <div className="d-flex w-100 gap-3 align-items-end">
                            <div className="w-30">
                              <select
                                className="form-control"
                                name="dialcode"
                                required
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  00
                                </option>
                                {DialCodeList.map((code, i) => {
                                  return (
                                    <option key={i} value={code}>
                                      {code}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="w-70">
                              <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="mobilephone"
                                aria-describedby="phoneHelp"
                                placeholder="company number"
                                maxLength="25"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="row g-3">
                        <div className="col-lg-8">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="country"
                          >
                            Country *
                          </label>
                          <select
                            className="form-select"
                            id="country"
                            name="country"
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select your country
                            </option>
                            {CountryList.map((name, i) => {
                              return (
                                <option key={i} value={name}>
                                  {name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="zipCode"
                          >
                            Zip / Postal Code *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="zipCode"
                            aria-describedby="zipcode"
                            placeholder="Zip / Postal Code"
                            name="zip"
                            required
                            maxLength="15"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <label
                            htmlFor="dateTime"
                            className="form-label text-secondary text-small"
                          >
                            Schedule Meeting *
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="dateTime"
                            aria-describedby="meetingDateTime"
                            name="scheduledate"
                            required
                          />
                        </div>
                        <div className="col-lg-6">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="timezone"
                          >
                            Time Zone *
                          </label>
                          <select
                            className="form-select"
                            id="timezone"
                            name="last_schedule_meeting_time_zone"
                            defaultValue=""
                            required
                          >
                            <option value="" disabled>
                              Select time zone (Utc )
                            </option>
                            {timezones.map((item, i) => {
                              const { text } = item;
                              return (
                                <option key={i} value={text}>
                                  {text}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label text-secondary text-small"
                        htmlFor="message"
                      >
                        Message (optional)
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="5"
                        placeholder="Tell us about your requirement."
                        maxLength="300"
                      />
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        defaultChecked={true}
                        type="checkbox"
                        value="newsletter"
                        id="newsletter"
                      />
                      <label
                        className="form-check-label fs-6"
                        htmlFor="newsletter"
                      >
                        Sign me up for newsletter
                      </label>
                    </div>

                    <div className="mb-3">
                      <button
                        type="submit"
                        className="button primary w-100"
                        disabled={this.state.requestOnGoing}
                      >
                        {this.state.requestOnGoing ? (
                          <>
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </>
                        ) : (
                          "Request Partnership"
                        )}
                      </button>
                    </div>
                  </form>
                  <small className="text-secondary">
                    By submitting this form you agree to our{" "}
                    <Link to="/privacy">Privacy Policy</Link> and our{" "}
                    <Link to="/terms">Terms of Use</Link>.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
