import React, { Component } from "react";
import ListComponent from "../../listComponent/listComponent";

export default class AllInOneLowCodeFeatureBlock extends Component {
  static getListData() {
    return [
      "Focus on use cases and not integration of individual applications.",
      "Removes friction and provides seamless user experience across applications",
      "Maintain data in one place, ensuring single source of truth across applications",
      "Ensure that everyone has access to correct data.",
    ];
  }
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-center g-5">
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Low code / No code visual designer
              </h6>
              <h3>All your Data and AI applications in one low code platform</h3>
              <ListComponent
                data={AllInOneLowCodeFeatureBlock.getListData()}
              ></ListComponent>
            </div>
            <div className="col-xxl-6 col-lg-6">
              <div className="image-grouping-wrapper">
                <img
                  src="/images/fromCodeToLowCode.svg"
                  width="100%"
                  alt="cynepia"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
