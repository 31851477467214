import "./AgentUseCases.scss";

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Domain, FactoryIcon } from "../../IconsCollections/IconsCollections";
import {
  navigationSectionsByFunction,
  navigationSectionsByIndustry,
} from "./AgentUseCasesData";

const TABS = [
  {
    id: "byFunction",
    title: "By Function",
    IconComponent: Domain,
    content: navigationSectionsByFunction,
  },
  {
    id: "byIndustry",
    title: "By Industry",
    IconComponent: FactoryIcon,
    content: navigationSectionsByIndustry,
  },
];

const TabButton = ({ active, tab, onClick }) => (
  <button
    onClick={() => onClick(tab.id)}
    className={`px-3 py-1 border rounded cursor-pointer fs-6 d-flex align-items-center gap-2 text-light ${
      active
        ? "glassMorphismBackground border-0 "
        : "bg-transparent border-primary-subtle opacity-75"
    }`}
    aria-label={`View by ${tab.title}`}
  >
    <tab.IconComponent fill="#fff" />
    {tab.title}
  </button>
);

const SectionListItem = ({ section, active, onClick }) => (
  <li
    onClick={() => onClick(section.tabIndex)}
    className={`fs-6 list-group-item list-group-item-action text-start cursor-pointer border-0 text-light rounded ${
      active ? "glassMorphismBackground " : "bg-transparent"
    }`}
    aria-current={active}
  >
    {section.tabTitle}
  </li>
);

const materialBackground = [
  "#607d8b",
  "#ff5722",
  "#ffc107",
  "#66bb6a",
  "#00bcd4",
  "#009688",
  "#29b6f6",
  "#f06292",
  "#ba68c8",
  "#8bc34a",
];

const ContentCard = ({ title, description, IconComponent, fillBackground }) => (
  <div
    className="shadow-sm glassMorphismBackground rounded px-3 py-3 h-100 d-grid gap-3 align-items-start "
    style={{ gridTemplateColumns: "50px 1fr" }}
  >
    <span
      className="w-100 rounded h-auto d-flex align-items-center justify-content-center p-2"
      style={{ backgroundColor: fillBackground }}
    >
      {IconComponent && (
        <IconComponent width="26px" height="26px" fill={"#ffffff"} />
      )}
    </span>
    <div>
      <h6 className="mb-2 ">{title}</h6>
      <p className="fs-6 text-secondary mb-0 ">{description}</p>
    </div>
  </div>
);

const AgentUseCasesSection = () => {
  const [activeTab, setActiveTab] = useState("byFunction");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const intervalRef = useRef(null);
  const activeTabRef = useRef(activeTab);
  const selectedSectionIndexRef = useRef(selectedSectionIndex);

  const currentTab = useMemo(
    () => TABS.find((tab) => tab.id === activeTab),
    [activeTab]
  );

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    selectedSectionIndexRef.current = selectedSectionIndex;
  }, [selectedSectionIndex]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const currentActiveTab = activeTabRef.current;
      const currentSelectedIndex = selectedSectionIndexRef.current;
      const currentTab = TABS.find((tab) => tab.id === currentActiveTab);
      const maxIndex = currentTab.content.length;

      if (currentSelectedIndex < maxIndex - 1) {
        setSelectedSectionIndex(currentSelectedIndex + 1);
      } else {
        const nextTab = TABS.find((tab) => tab.id !== currentActiveTab);
        setActiveTab(nextTab.id);
        setSelectedSectionIndex(0);
      }
    }, 1300);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  const handleTabChange = useCallback((tabId) => {
    setActiveTab(tabId);
    setSelectedSectionIndex(1);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const handleSectionClick = useCallback((index) => {
    setSelectedSectionIndex(index);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const getMaterialBackground = useCallback((sectionIndex, contentIndex) => {
    const materialIndex =
      (sectionIndex + contentIndex) % materialBackground.length;
    return materialBackground[materialIndex] || "#607d8b";
  }, []);

  const selectedSection = currentTab.content.find(
    (section) => section.tabIndex === selectedSectionIndex
  );

  return (
    <section className="page-section" id="agentUseCasesSection">
      <div className="page-container container rounded-5 py-5 px-5">
        <h6 className="text-light text-opacity-75">XCEED AGENTFORCE</h6>
        <h4 className="mb-5 text-light">Use Cases By Task & Industry</h4>

        <div className="d-flex gap-2 mb-5">
          {TABS.map((tab) => (
            <MemoTabButton
              key={tab.id}
              tab={tab}
              active={activeTab === tab.id}
              onClick={handleTabChange}
            />
          ))}
        </div>

        <div className="container-fluid px-0">
          <div className="row g-3">
            <div className="col-12 col-md-3">
              <ul className="list-group bg-transparent">
                {currentTab.content.map((section) => (
                  <MemoSectionListItem
                    key={section.tabIndex}
                    section={section}
                    active={section.tabIndex === selectedSectionIndex}
                    onClick={handleSectionClick}
                  />
                ))}
              </ul>
            </div>

            <div className="col-12 col-md-9 contentContainer p-3 rounded-3 glassMorphismBackground">
              <div className="row g-3 ">
                {selectedSection && (
                  <div
                    key={selectedSection.tabIndex}
                    id={`section-${selectedSection.tabIndex}`}
                  >
                    <div className="row g-3">
                      {selectedSection.content.map((content, index) => (
                        <div
                          key={`${selectedSection.tabIndex}-${index}`}
                          className="col-12 col-md-6 col-xl-6"
                        >
                          <MemoContentCard
                            {...content}
                            fillBackground={getMaterialBackground(
                              selectedSection.tabIndex,
                              index
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MemoTabButton = React.memo(({ tab, active, onClick }) => (
  <TabButton tab={tab} active={active} onClick={onClick} />
));
const MemoSectionListItem = React.memo(({ section, active, onClick }) => (
  <SectionListItem section={section} active={active} onClick={onClick} />
));
const MemoContentCard = React.memo(ContentCard);

export default AgentUseCasesSection;
