import React from "react";
import { Link } from "react-router-dom";

const GetStartedButton = ({ classNames }) => {
  const btnClassNames = `button primary ${classNames ? classNames : ""}`;

  return (
    <Link to="/get-started">
      <button className={btnClassNames}>Get Started</button>
    </Link>
  );
};

export default GetStartedButton;
