import React, { Component } from "react";
import "./quotedText.scss";

export default class CynepiaClientsQuotations extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container accent-bg-2 p-5">
          <div className="row justify-content-center text-center">
            <h2>What are our Customers Saying ?</h2>
            <div className="col-lg-12">
              <div className="quoted-text-wrapper position-relative d-flex">
                <div className="quoted-icon position-relative"></div>
                <div className="quoted-text ">
                  <div className="text fs-6 mt-3">
                    <p>
                      Cynepia helped us meet our immediate objectives of
                      bringing data together various financial and IT systems
                      together, which in-turn helped improve various data and
                      financial processes, helping us achieve the speed and
                      efficiency with those processes. The more we were exposed
                      to the same, more we understood and learnt about what we
                      can achieve and now have embedded Xceed Intelligence Platform into
                      various processes including sanctions verification, branch
                      performance automation, cross-sell, financial and hr
                      processes.
                    </p>
                    <p>
                      More specifically automation of these data and business
                      processes have saved us hours of manual spreadsheet work
                      and helped us achieve organisation growth targets while
                      breaking the correlation between workforce and those
                      respective processes.
                    </p>
                  </div>

                  <div className="fs-5 mt-3">
                    - CIO of a large Financial Institution
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
