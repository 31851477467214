import React from "react";

import HeroSection from "./heroSection";
import ValueProposition from "./valuePropsition";
import DataDrivenCulture from "./dataDrivenCulture";
import AllInOneLowCodeFeatureBlock from "./allInOneLowCodeFeatureBlock";
import FeatureSet from "./featureSet";
import FeaturedBlogSection from "../../featuredBlogSection/featuredBlogSection";
import AllFeatures from "./allFeatures";
import GetStartedSection from "../../getStartedSection/getStartedSection";
import "./home.scss";
import AiFeatureSection from "./aiFeatureSection/aiFeatureSection";
import AgentUseCasesSection from "./AgentUseCasesSection";

function Home() {
  return (
    <div id="homePage" className="page">
      <HeroSection />
      <AiFeatureSection />
      <AgentUseCasesSection />
      <ValueProposition />
      <AllFeatures />
      <DataDrivenCulture />
      <AllInOneLowCodeFeatureBlock />
      <FeatureSet />
      <FeaturedBlogSection />
      <GetStartedSection />
    </div>
  );
}

export default Home;
