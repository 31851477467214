import React, { useState, useEffect } from "react";
import { Transition } from "semantic-ui-react";
import CtaButtons from "../../ctaButtons/ctaButtons";
import "./heroSection.scss";

const HeroSection = () => {
  const [heroImageVisible, setHeroImageVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setHeroImageVisible(true);
  }, []);

  return (
    <div className="hero-container-wrap page-section">
      <Transition
        visible={heroImageVisible}
        animation="fade down"
        duration={1000}
      >
        <div className="container page-container hero-container text-center d-flex g-5 flex-row">
          <h1>
            Start deriving
            <br />
            <span className="text-primary">Limitless Value</span> with
            <br />
            <span className="text-primary">Xceed Intelligence Platform </span>
          </h1>
          <div className="sub-title text-secondary fs-5 mb-4">
            Xceed Intelligence Platform empowers your AI engineers and data teams to
            <br />
            supercharge your enterprise productivity using data and AI agent applications.
          </div>
          <CtaButtons center={true} />
          <div
            className="hero-image h-100 position-relative d-flex align-items-center justify-content-center"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <img
              src="images/homePageHeroBanner.svg"
              alt="Cynepia"
              width="100%"
              className="m-auto p-1"
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default HeroSection;
